import { CurrencyModel } from "./../../../api/models/company/currency-model";
import { CurrencyService } from "./../../../services/currency-service";
import { ServiceCallQuotationItemType } from "api/enums/service-call-quotation-item-type";
import { CatalogCostManagementMode } from "api/enums/catalog-cost-management-mode";
import { ServiceCallQuotationItemModel } from "api/models/company/service-call/service-call-quotation-item-model";
import { PLATFORM, computedFrom, observable } from "aurelia-framework";
import { ValidationController, ValidationRules, validateTrigger } from "aurelia-validation";
import { ValidationHelper } from "helpers/validation-helper";
import { QuotationItemBase } from "pages/services/quotations/item-base";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import { ApiHelper } from "helpers/api-helper";
import { SettingRepository } from "repositories/setting-repository";
import { ServiceCallNonBillingReasonService } from "services/service-call-non-billable-reason-service";
import { I18N } from "aurelia-i18n";
import { inject, NewInstance } from "aurelia-dependency-injection";
import { DispatchTemplateModel } from "api/models/company/template/dispatch-template-model";
import { DispatchTemplateService } from "services/dispatch-template-service";
import { ServiceCallQuotationPricingType } from "api/enums/service-call-quotation-pricing-type";
import { ServiceCallQuotationPriceService } from "services/service-call-quotation-price-service";

const defaultScaleFactor = 7;

@inject(NewInstance.of(ValidationController))
export class QuotationItemMaterialBase extends QuotationItemBase {
    @observable() //
    public selectedItem: any | null = null;

    @observable()
    public unitCost: number = 0;

    @observable()
    public profitMargin: number = 0;

    @observable()
    public sellingPrice: number = 0;

    public originalQuantity: number = 0;
    public catalogBaseUrl: string = "";
    public section: string = "";
    public templateResult: string = PLATFORM.moduleName("pages/templates/maSelectTemplates/material_edit_template.html");
    public requisitionId: number = -1; // TODO à remplacer par un boolean eventuellement, hideQtyInStockLine, sert à cacher la ligne Qty in stock et site
    public readonly ServiceCallQuotationItemType: typeof ServiceCallQuotationItemType = ServiceCallQuotationItemType;
    public readonly CatalogCostManagementMode: typeof CatalogCostManagementMode = CatalogCostManagementMode;

    public readonly serviceCallQuotationPricingType: typeof ServiceCallQuotationPricingType = ServiceCallQuotationPricingType;
    public readonly NotInCatalogPrefix: string = "!";
    public showNotInCatalog: boolean = false;
    public calculationInProgress: boolean = false;

    public currency: CurrencyModel | null = null;
    public currencyRate: number = 0;
    public currencySymbol: string = "";
    @observable()
    public currencyUnitPrice: number = 0;

    @computedFrom("section")
    public get predefinedSentencesOption(): string {
        return this.section;
    }

    @computedFrom("selectedItem")
    public get unitDescription(): string {
        if (this.selectedItem === null) {
            return "";
        }

        return this.selectedItem.data.UnitDescription;
    }

    @computedFrom("selectedItem")
    public get otherCatalogDescription(): string {
        if (this.quotationItem === null) {
            return "";
        }

        if (this.isOutOfCatalogItem(this.quotationItem.CatalogId)) {
            return "";
        }

        return this.quotationItem.CatalogDescription2 + "\r\n" + this.quotationItem.CatalogDescription3;
    }

    @computedFrom("selectedItem", "quotationItem")
    public get inventoryScaleFactor(): number {
        return this.quotationItem.InventoryScaleFactor;
    }

    @computedFrom("quotation.IsLumpSum", "quotationItem")
    public get minLumpSumPlus(): number {
        if (this.quotation !== null && this.quotationItem !== null) {
            return this.quotation.IsLumpSum === this.serviceCallQuotationPricingType.LumpSumPlus ? this.minLumpSumPlusSaleAmount : this.minSaleAmount;
        }

        return this.minSaleAmount;
    }

    constructor(
        i18N: I18N,
        serviceCallQuotationService: ServiceCallQuotationService,
        serviceCallNonBillingReasonService: ServiceCallNonBillingReasonService,
        private readonly validationHelper: ValidationHelper,
        private readonly validationController: ValidationController,
        private readonly apiHelper: ApiHelper,
        private readonly settingRepository: SettingRepository,
        private readonly dispatchTemplateService: DispatchTemplateService,
        private readonly serviceCallQuotationPriceService: ServiceCallQuotationPriceService,
        public readonly currencyService: CurrencyService
    ) {
        super(i18N, serviceCallQuotationService, serviceCallNonBillingReasonService);
    }

    public async selectedItemChanged(newValue: any): Promise<void> {
        if (!this.quotation || !this.quotation.Items) {
            return;
        }

        if (newValue === null || newValue.data === null || newValue.data.Code === undefined) {
            return;
        }

        this.quotationItem.InventoryScaleFactor = newValue.data.InventoryScaleFactor ? newValue.data.InventoryScaleFactor : defaultScaleFactor;

        if (!this.isOutOfCatalogItem(newValue.data.Code)) {
            this.quotationItem.OutCatalogCode = "";
            this.quotationItem.Description = newValue.data.ClientDescription1;
            this.quotationItem.CatalogDescription1 = newValue.data.ClientDescription1;
            this.quotationItem.CatalogDescription2 = newValue.data.ClientDescription2;
            this.quotationItem.CatalogDescription3 = newValue.data.ClientDescription3;
            this.quotationItem.CostManagementMode = newValue.data.CostManagementMode;
        } else if (!this.readonly) {
            this.quotationItem.Description = this.selectedItem.data ? (this.selectedItem.data.scannedValue ? this.selectedItem.data.scannedValue : "") : this.selectedItem.text;
        }

        if (!this.isOutOfCatalogItem(newValue.data.Code) && newValue.data.Balance !== 0) {
            this.quotationItem.Quantity = newValue.data.Balance;
        } else {
            this.quotationItem.CatalogId = this.NotInCatalogPrefix;
        }

        if (this.quotationItem.Type === ServiceCallQuotationItemType.BillingItem) {
            this.quotationItem.RevenueType = newValue.data.RevenueType;
        } else {
            if (this.selectedItem.id) {
                this.quotationItem.CatalogId = this.selectedItem.id;
                this.sellingPrice = 0;
                this.quotationItem.UnitPrice = 0;
                this.quotationItem = await this.serviceCallQuotationPriceService.GetItemPrices(this.quotation, this.quotationItem);
            }
        }

        if (this.quotation.ProfitMargin === 0) {
            this.sellingPrice = this.quotationItem.UnitPrice;
            this.unitCost = this.quotationItem.UnitCost;
        } else {
            this.calculationInProgress = true;
            this.unitCost = this.quotationItem.UnitCost;
            this.quotationItem.ProfitMargin = this.quotation.ProfitMargin;
            this.profitMargin = this.quotation.ProfitMargin;
            this.sellingPrice = this.serviceCallQuotationPriceService.UpdateItemUnitPriceWithProfitMargin(this.quotation.ProfitMargin, this.quotationItem);
            this.calculationInProgress = false;
        }
    }

    public profitMarginChanged(newValue: any, oldValue: any): void {
        const isValueUnchanged = newValue === oldValue;

        if (this.calculationInProgress || !this.quotationItem || isValueUnchanged) {
            return;
        }

        if (newValue === null) {
            this.quotationItem.ProfitMargin = 0;
            newValue = 0;
        } else {
            this.quotationItem.ProfitMargin = newValue;
        }

        if (this.sellingPrice >= 0) {
            this.calculationInProgress = true;
            this.sellingPrice = this.serviceCallQuotationPriceService.UpdateItemUnitPriceWithProfitMargin(newValue, this.quotationItem);
            this.calculationInProgress = false;
        }
    }

    public unitCostChanged(newValue: any, oldValue: any): void {
        const isValueUnchanged = newValue === oldValue;

        if (!this.quotation || !this.quotation.Items || !this.quotationItem || isValueUnchanged || (this.quotationItem.CostManagementMode !== CatalogCostManagementMode.Variable && this.quotationItem.CostManagementMode !== CatalogCostManagementMode.Undefined)) {
            return;
        }

        this.quotationItem.UnitCost = newValue;

        if (this.quotation.IsLumpSum === this.serviceCallQuotationPricingType.LumpSumPlus) {
            if (this.quotationItem.IsBillable) {
                if (this.quotation.ProfitMargin !== 0) {
                    this.calculationInProgress = true;
                    this.sellingPrice = this.serviceCallQuotationPriceService.UpdateItemUnitPriceWithProfitMargin(this.quotation.ProfitMargin, this.quotationItem);
                    this.calculationInProgress = false;
                } else {
                    this.calculationInProgress = true;
                    this.profitMargin = this.serviceCallQuotationPriceService.UpdateItemProfitMargin(this.quotationItem);
                    this.calculationInProgress = false;
                }
            } else {
                this.calculationInProgress = true;
                this.quotationItem.ProfitMargin = 0;
                this.profitMargin = 0;
                this.quotationItem.UnitPrice = 0;
                this.sellingPrice = 0;
                this.calculationInProgress = false;
            }
        } else if (this.quotation.IsLumpSum === this.serviceCallQuotationPricingType.TimeMaterial && !this.quotation.IsLockedQuotation && (this.quotationItem.CostManagementMode === CatalogCostManagementMode.Variable || this.quotationItem.CostManagementMode === CatalogCostManagementMode.Undefined)) {
            this.quotationItem.UnitPrice = 0;
            this.sellingPrice = 0;
        }
    }

    public sellingPriceChanged(newValue: any, oldValue: any): void {
        const isValueUnchanged = newValue === oldValue;

        if (this.calculationInProgress || !this.quotation || !this.quotation.Items || !this.quotationItem || isValueUnchanged) {
            return;
        }

        this.quotationItem.UnitPrice = newValue;

        if (this.quotation.IsLumpSum === this.serviceCallQuotationPricingType.LumpSumPlus) {
            this.calculationInProgress = true;
            this.profitMargin = this.serviceCallQuotationPriceService.UpdateItemProfitMargin(this.quotationItem);
            this.calculationInProgress = false;
        }
    }

    public selectedCurrencyChanged(event: any): void {
        if (event && event.detail) {
            this.currencyRate = event.detail.Rate;
            this.unitCost = this.currencyUnitPrice * this.currencyRate;
            this.currencySymbol = event.detail.Symbol;
        }
    }

    public async getCurrencies(): Promise<CurrencyModel[] | null> {
        return await this.currencyService.GetQuotationCurrencies(this.quotation.Id);
    }

    public currencyUnitPriceChanged(): void {
        if (this.currencyRate !== 0) {
            this.unitCost = this.currencyUnitPrice * this.currencyRate;
        } else {
            this.unitCost = this.currencyUnitPrice;
        }

    }

    protected initSelectedItem(data: ServiceCallQuotationItemModel): void {
        let selectedItem = null;

        if (data.CatalogId !== null) {
            if (this.isOutOfCatalogItem(data.CatalogId)) {
                selectedItem = {
                    id: data.CatalogId,
                    text: this.i18n.tr("AddItemNotInCatalog"),
                    data: data
                };
            } else {
                selectedItem = {
                    id: data.CatalogId,
                    text: data.CatalogId,
                    data: data
                };
            }
        }

        this.selectedItem = selectedItem as any;
    }

    protected async updateLinkedItems(): Promise<void> {
        if (this.quotation === null) {
            return;
        }

        if (this.quotation.IsWorkOrder) {
            return;
        }

        if (!this.quotationItem) {
            return;
        }

        const linkedItems = await this.serviceCallQuotationService.createQuotationItemLinkedItems(
            this.quotation.Id,
            this.quotation.ContractId,
            this.selectedItem.id,
            this.quotationItem.Quantity
        );

        if (linkedItems !== null && linkedItems.length > 0) {
            linkedItems.forEach((x: ServiceCallQuotationItemModel) => { x.ParentId = this.quotationItem.Id; });

            if (this.quotationItem.LinkedItems !== null && this.quotationItem.LinkedItems!.length > 0) {
                const isNotNewItem = this.quotationItem.LinkedItems[0]!.Id > 0;

                if (isNotNewItem) {
                    this.quotationItem.LinkedItems[0]!.Quantity = linkedItems[0].Quantity;
                    return;
                }
            }
        }

        this.quotationItem.LinkedItems = linkedItems;
    }

    protected isOutOfCatalogItem(code: string | null): boolean {
        if (!code || code.length === 0) {
            return false;
        }

        return code.charAt(0) === this.NotInCatalogPrefix;
    }

    protected initValidation(): void {
        this.validationController.validateTrigger = validateTrigger.manual;

        ValidationRules
            .ensure((x: QuotationItemMaterialBase) => x.selectedItem).required().withMessageKey("err_ItemRequired")
            .ensure((x: QuotationItemMaterialBase) => x.unitCost).satisfies((value: number) => value >= 1).when((x: QuotationItemMaterialBase) => x.quotationItem.Type === ServiceCallQuotationItemType.Material && (x.quotationItem.CostManagementMode === CatalogCostManagementMode.Variable || x.quotationItem.CostManagementMode === CatalogCostManagementMode.Undefined)).withMessageKey("err_MinCostAmount")
            .on(this);

        ValidationRules
            .ensure((x: ServiceCallQuotationItemModel) => x.Quantity).required().withMessageKey("err_QuantityRequired")
            .ensure((x: ServiceCallQuotationItemModel) => x.Quantity).satisfies((value: number) => value > 0).withMessageKey("err_MinQuantity")
            .ensure((x: ServiceCallQuotationItemModel) => x.Description).required().withMessageKey("err_DescriptionRequired")
            .ensure((x: ServiceCallQuotationItemModel) => x.NonBillableReasonId).required().when((x: ServiceCallQuotationItemModel) => !x.IsBillable).withMessageKey("err_NonBillableReasonRequired")
            .on(this.quotationItem);
    }

    protected async initCatalogBaseUrl(): Promise<void> {
        if (this.quotation === null) {
            return;
        }

        let dispatchTemplateId = this.settingRepository.getDispatchTemplate();

        // Pourquoi ça peut retourner null dans une string???
        if (dispatchTemplateId === "null" || dispatchTemplateId === "undefined") {
            dispatchTemplateId = null;
        }

        let dispatchTemplate = null;
        if (this.quotation.SourceDispatchId !== 0) {
            dispatchTemplate = await this.dispatchTemplateService.getDispatchTemplatesByDispatchId(this.quotation.SourceDispatchId);
        } else if (dispatchTemplateId !== null) {
            dispatchTemplate = await this.dispatchTemplateService.GetDispatchTemplatesByCode(dispatchTemplateId);
        }

        if (dispatchTemplate !== null) {
            dispatchTemplateId = dispatchTemplate.Code;
            this.showNotInCatalog = dispatchTemplate.ShowNotInCatalogMaterialService;
        }

        if (this.quotationItem.Type === ServiceCallQuotationItemType.Material) {
            this.catalogBaseUrl = this.apiHelper.getBaseApiUrlForCurrentCompany() + "/catalog/service-call/quotation/material?quotationId=" + this.quotationItem.QuotationId + (dispatchTemplateId ? "&dispatchTemplateId=" + dispatchTemplateId : "");
        } else if (this.quotationItem.Type === ServiceCallQuotationItemType.BillingItem || this.quotationItem.Type === ServiceCallQuotationItemType.MiscCost) {
            this.catalogBaseUrl = this.apiHelper.getBaseApiUrlForCurrentCompany() + "/catalog/service-call/quotation/material-billing?quotationId=" + this.quotationItem.QuotationId + (dispatchTemplateId ? "&dispatchTemplateId=" + dispatchTemplateId : "");
        }
    }

    protected async validate(): Promise<boolean> {
        return await this.validationHelper.validateControllerAndNotifyUserIfNecessary(this.validationController);
    }
}
