import { inject, singleton} from "aurelia-dependency-injection";

import { default as ko } from "knockout";
import { default as _ } from "underscore";
import { default as dateHelper } from "helpers/dateHelper";
import { default as enumHelper } from "helpers/enumHelper";
import { default as resx } from   "core/resx";
import { default as services } from   "services/serviceService";
import { default as routerHelper }  from  "helpers/routerHelper";
import { default as labelHelper } from   "helpers/labelHelper";
import { default as settingHelper } from   "helpers/settingHelper";
import { default as settings } from   "repositories/settingRepository";
import { default as notificationHelper } from   "helpers/notificationHelper";
import { default as pdfTransactionStatusHelper } from "helpers/pdfTransactionStatusHelper";
import RouteRepository from "repositories/routeRepository";
import UserAccessService from "services/user-access-service";
import { WorkOrderMaintenanceType } from "api/enums/work-order-maintenance-type";
import { EmergencyTypes } from "enums/emergency-types";
import { CallType } from "api/enums/call-type";
import { UnitSystem } from "api/enums/unit-system";
import UserSettingService from "services/userSettingsService";
import { GeolocationService } from "services/geolocationService";
import { default as stringHelper } from "helpers/stringHelper";
import { ServiceCallEmergencyTypeService } from "services/service-call-emergency-type-service";

import "widgets/consultHoursWorkedButton";
import "widgets/pageBottom";

var SECTION_TABS = {
    ONGOING: 1,
    UNPLANNED: 2,
    COMPLETED: 3
};

var self = null;

function bindViewModel() {
    self.servicePresenceTypes = _.values(enumHelper.servicePresence());
    self.serviceStatus = _.values(enumHelper.serviceStatus());
}

function load() {
    if (!settings.getCompany()) {
        return jQuery.Deferred().resolve();
    }
    else if (self.sectionShown() === SECTION_TABS.COMPLETED) {
        return loadPreviousData();
    } else {
        return loadData();
    }
}

function loadPreviousData() {
    self.page = 1;
    return getPreviousData().done(function (ls) {
        ls = getPreviousDataDone(ls);
        self.historyList(ls);
        self.list(ls);
    });
}

function loadUnplannedData() {
    self.page = 1;
    return getUnplannedData().done(function (ls) {
        ls = getUnplannedDataDone(ls);
        self.unplannedList(ls);
        self.list(ls);
    });
}

function getUnplannedDataDone(data) {
    if (data.morePages) {
        initScroll();
    } else {
        self.completedFinishedLoading = true;
        disposeScroll();
    }

    initializeCallList(data)

    return initializeUnplannedCallsList(data);
}

function getPreviousData(page) {
    routerHelper.showLoading();

    return services.getPrevious(page)
        .fail(disposeAll)
        .always(routerHelper.hideLoading);
}

async function getLocation() {
    try {
        self.position = await self.geolocationService.getCurrentLocation();
    } catch (error) {
        self.position = null;
        routerHelper.hideLoading();
        if (stringHelper.startsWith(error.message, "Only secure origins are allowed") || error.message.indexOf("Access to geolocation was blocked over insecure connection") > -1 || error.message.indexOf("User denied Geolocation") > -1) {
            notificationHelper.showWarning(resx.localize("LocationRequireSSL"), null, { timeOut: 0 });
        } else {
            notificationHelper.showWarning(error.message, null, { timeOut: 0 });
        }
    }         
}

async function getUnplannedData(page) {
    await getLocation()
    routerHelper.showLoading();
    let emergencyId = null;
    if (self.selectedEmergencyType) {
        emergencyId = self.selectedEmergencyType.id === self.emergencyTypeAll.id ? null : self.selectedEmergencyType.id;
    }
    return services.getUnplanned(page, self.selectedWithinRange.id, getUnitSystem(), self.position ? self.position.coords.latitude : 0, self.position ? self.position.coords.longitude : 0, emergencyId)
        .fail(disposeAll)
        .always(routerHelper.hideLoading);
}

function getPreviousDataDone(data) {
    if (data.morePages) {
        initScroll();
    } else {
        self.completedFinishedLoading = true;
        disposeScroll();
    }

    return initializeCallList(data);
}

function loadData() {
    routerHelper.showLoading();
    return services.getCurrent(settings.getDispatchTemplate())
        .done(initialise)
        .fail(disposeAll())
        .always(routerHelper.hideLoading);
}

function initialise(data) {
    var ls = initializeCallList(data);
   
    self.addEntry = data.addEntry;
    self.showAddEntryButton(data.addEntry !== enumHelper.serviceCallAddEntryStatus().NO);

    self.addWO = data.addWO;
    self.showAddWOButton(data.addWO !== enumHelper.serviceCallAddEntryStatus().NO);

    self.currentList(ls);
    self.show(self.sectionShown());
}

function initializeCallList(data) {
    var ls = [];

    _.each(data.Calls, function (call) {
        appendPropertiesToCall(call);

        if (call.AssignedDate) {
            if (!_.findWhere(ls, { date: dateHelper.dateFromUTC(call.AssignedDate, "ddd, ll") })) {
                ls.push({
                    date: dateHelper.dateFromUTC(call.AssignedDate, "ddd, ll"),
                    helperName: call.HelperName,
                    assignedEquipmentDescription: call.AssignedEquipmentDescription,
                    ls: [],
                    isOpened: ko.observable(true)
                });
            }
        }
    });

    pushCallsByDate(ls, data);

    return ls;
}

function appendPropertiesToCall(call) {
    call.lblAssignmentStatus = initializeAssignmentStatus(call);
    initializePdfStatus(call);
}

function initializeAssignmentStatus(call) {
    var temp = (call.ServiceType === 'S' ? self.labelHelper.getCallTypeLabel(call.CallType) : self.resx.localize('WorkOrder')) + ' ' + call.Id;
    if (call.Presence !== self.enumPresence.COMPLETED.id) {
        temp += ' - ' + self.getPresence(call.Presence).label;
    }

    return temp;
}

function initializePdfStatus(call) {
    if (call.PDFTransactionStatus === '') {
        call.showPdfStatus = false;
        call.showPdfLog = false;
    } else {

        call.PdfStatus = pdfTransactionStatusHelper.getPdfStatus(call.PDFTransactionStatus);
        call.showPdfStatus = call.PdfStatus && (call.PdfStatus.sendStatus !== self.enumPdfStatus.NOTPROCESSED || call.PdfStatus.saveStatus !== self.enumPdfStatus.NOTPROCESSED);
        call.showPdfLog = call.PdfStatus && (call.PdfStatus.sendStatus === self.enumPdfStatus.ERROR || call.PdfStatus.saveStatus === self.enumPdfStatus.ERROR);
        if (call.PdfStatus) {
            switch (call.PdfStatus.sendStatus) {
            case self.enumPdfStatus.NOTPROCESSED:
                call.PdfStatus.sendClass = 'fa fa-fw';
                break;
            case self.enumPdfStatus.PENDING:
                call.PdfStatus.sendClass = 'yellow ma ma-send';
                break;
            case self.enumPdfStatus.SUCCESS:
                call.PdfStatus.sendClass = 'green ma ma-send';
                break;
            case self.enumPdfStatus.ERROR:
                call.PdfStatus.sendClass = 'red ma ma-send-error';
                break;
            default:
                break;
            }

            switch (call.PdfStatus.saveStatus) {
            case self.enumPdfStatus.NOTPROCESSED:
                call.PdfStatus.saveClass = 'fa fa-fw';
                break;
            case self.enumPdfStatus.PENDING:
                call.PdfStatus.saveClass = 'yellow ma ma-saveToDisk';
                break;
            case self.enumPdfStatus.SUCCESS:
                call.PdfStatus.saveClass = 'green ma ma-saveToDisk';
                break;
            case self.enumPdfStatus.ERROR:
                call.PdfStatus.saveClass = 'red ma ma-saveToDisk-error';
                break;
            default:
                break;
            }
        }
    }
}

function initializeUnplannedCallsList(data) {
    var unls = [];

    var tempUnls = _.filter(data.Calls, function (call) { return !call.AssignedDate; });
    if (tempUnls.length > 0) {
        unls.push({
            date: "",
            helperName: "",
            assignedEquipmentDescription: "",
            ls: tempUnls,
            isOpened: ko.observable(true)
        });
    }

    return unls;
}

function pushCallsByDate(ls, data) {
    _.each(ls, function (item) {
        item.ls = _.filter(data.Calls, function (call) {
            return dateHelper.dateFromUTC(call.AssignedDate, "ddd, ll") === item.date;
        });
    });
}

function disposeAll() {
    self.currentList([]);
    self.unplannedList([]);
    self.historyList([]);
    self.list([]);
    disposeScroll(self);
}

function initScroll() {
    jQuery(document).scroll(scrollHandler);
    showScrollMessage("scroll");
}

function disposeScroll() {
    jQuery(document).off("scroll", scrollHandler);
    hideScrollMessage();
}

function scrollHandler() {
    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        disposeScroll();
        showScrollMessage("loading");
        self.page += 1;
        getPreviousData(self.page)
            .done(function (ls) {
                var actualLs = self.list();
                ls = getPreviousDataDone(ls);
                actualLs = actualLs.concat(ls);
                self.list(actualLs);
            })
            .fail(function () {
                self.list([]);
            });
    }
}

function showScrollMessage(msgId) {
    if (msgId === "loading") {
        self.scrollMessageText(resx.localize("LoadingMoreResults"));
    } else {
        self.scrollMessageText(resx.localize("ScrollForMore"));
    }

    self.showScrollMessage(true);
}

function hideScrollMessage() {
    self.showScrollMessage(false);
}

function getUnitSystem() {
    const unitSystem = settings.getUnitSystem();
    return unitSystem ? unitSystem : UnitSystem.Metric
}


@singleton()
@inject(RouteRepository, UserAccessService, GeolocationService, ServiceCallEmergencyTypeService)
export class ServiceIndex {
    dateHelper = dateHelper;
    enumPresence = enumHelper.servicePresence();
    enumPdfStatus = enumHelper.servicePdfStatus();
    labelHelper = labelHelper;
    resx = resx;
    routerHelper = routerHelper;

    sectionTabs = SECTION_TABS;
    showAddEntryButton = ko.observable(false);
    showAddWOButton = ko.observable(false);
    addEntry = "";
    addWO = "";

    currentList = ko.observableArray([]);
    historyList = ko.observableArray([]);
    unplannedList = ko.observableArray([]);

    lblAssignmentStatus = '';

    completedFinishedLoading = false;
    list = ko.observableArray([]);
    page = 1;
    sectionShown = ko.observable(SECTION_TABS.ONGOING);
    servicePresenceTypes = [];
    serviceStatus = [];
    showScrollMessage = ko.observable(false);
    scrollMessageText = ko.observable(resx.localize('ScrollForMore'));
    workOrderMaintenanceType = WorkOrderMaintenanceType;
    emergencyTypeEnum = EmergencyTypes;
    callType = CallType;
    selectedWithinRange = "";
    emergencyTypeAll = { id: "-1", text: resx.localize('AllEmergencyTypes')};
    selectedEmergencyType = null;
    userSelectedWithinRangeId = ko.observable(-1);
    UnitSystemToDisplay = UnitSystem.Metric;
    unitSystemMetric = UnitSystem.Metric;
    unitSystemImperial = UnitSystem.Imperial;
    position = null;

    constructor(routeRepository, userAccessService, geolocationService, serviceCallEmergencyTypeService) {
        self = this;
        this.routeRepository = routeRepository;
        this.userAccessService = userAccessService;
        this.geolocationService = geolocationService;
        this.serviceCallEmergencyTypeService = serviceCallEmergencyTypeService;
        this.enumHelper = enumHelper;
    }

    async activate() {
        bindViewModel();
        await this.loadWithinRanges();
        await this.loadEmergencyTypes();
        await load();
    }

    deactivate() {
        disposeScroll(self);
    }

    async selectedWithinRangeValueChanged() {
        if (this.sectionShown() === this.sectionTabs.UNPLANNED) {
            await UserSettingService.setUnPlannedServiceCallWithinRangeSetting(this.selectedWithinRange.id, getUnitSystem());
            loadUnplannedData();
        }
    }

    async selectedEmergencyTypeValueChanged() {
        if (this.sectionShown() === this.sectionTabs.UNPLANNED) {
            await UserSettingService.setUnPlannedServiceCallWithinRangeSetting(this.selectedWithinRange.id, getUnitSystem());
            loadUnplannedData();
        }
    }

    async loadWithinRanges() {
        this.withinRanges = this.getWithinRanges();

        const usersetting = await UserSettingService.getUnPlannedServiceCallWithinRangeSetting(getUnitSystem());
        if (usersetting) {
            self.userSelectedWithinRangeId(usersetting.Value);
        }

        this.selectedWithinRange = this.withinRanges.find(unitSystem => unitSystem.id === self.userSelectedWithinRangeId());
    }

    async loadEmergencyTypes() {
        self.selectedEmergencyType = self.emergencyTypeAll;
        let selectedEmergencyTypeInit = [self.emergencyTypeAll];
        let emergencyTypestbl = _.map(_.filter(await this.serviceCallEmergencyTypeService.getEmergencyTypes(), function (etype) { return etype.Description !== ""; }  ), (emergencyType) => {
            return { id: emergencyType.Id, text: emergencyType.Id + " - " + emergencyType.Description };
        });

        self.emergencyTypes = selectedEmergencyTypeInit.concat(emergencyTypestbl);
    }

    getWithinRanges() {
        this.UnitSystemToDisplay = getUnitSystem()

        if (this.UnitSystemToDisplay !== UnitSystem.Imperial) {
            return enumHelper.serviceCallMetricDtistances();
        }
    
        return enumHelper.serviceCallImperialDtistances();
    }

    show(param) {
        self.sectionShown(param);
        switch (param) {
        case SECTION_TABS.UNPLANNED:
            //validate if template has been selected for entry adding button
            if (self.addEntry !== enumHelper.serviceCallAddEntryStatus().MISSINGTEMPLATE) {
                loadUnplannedData();
            } else {
                settingHelper.getSelectedDispatchModel();
            }
            disposeScroll();
            break;
        case SECTION_TABS.COMPLETED:
            if (self.historyList().length === 0) {
                loadPreviousData();
            } else {
                self.list(self.historyList());
                if (!self.completedFinishedLoading) {
                    initScroll();
                }
            }
            break;
        default:
            self.list(self.currentList());
            disposeScroll(self);
        }
    }

    showPdfLog(call) {
        if (call.showPdfLog) {
            notificationHelper.showDialogOk(call.PdfStatus.message, call.PdfStatus.title);
        }
    }

    getPresence(presenceValue) {
        return _.first(_.where(self.servicePresenceTypes, { id: presenceValue }));
    }

    toggleOpenSection(section) {
        section.isOpened(!section.isOpened());
    }

    goToAddEntry() {
        if (settingHelper.getSelectedDispatchModel() !== "") {
           routerHelper.navigateToRoute(this.routeRepository.routes.Service_Add.name);
        }
    }

    goToAddWO() {
        if (settingHelper.getSelectedDispatchModel() !== "") {
            routerHelper.navigateToRoute(this.routeRepository.routes.Service_Add_Wo.name);
        }
    }

    navigateToQuotations() {
        routerHelper.navigateToRoute(this.routeRepository.routes.Service_Quotations_Mobile.name);
    }

    navigateToQuotation(quotationId) {
        routerHelper.navigateToRoute(this.routeRepository.routes.Service_Quotation_Mobile.name, { quotationId: quotationId });
    }

    getMaintenanceTypeDescription(maintenanceType) {
        if (maintenanceType) {
            return resx.localize("WorkOrderMaintenanceType_" + maintenanceType);
        }
        return "";
    }

}